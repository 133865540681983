<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      ref="edit_field"
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/member/UpdateMember"
      finish-router="member"
      permission="Mem_Edit"
      @beforeSubmit="beforeSubmit"
    >
      <template #facility="scope">
        <SlotFacility :row-data="scope.rowData" :company-id="scope.rowData.companyID"
                      :facility-id="scope.rowData.facilityID" :is-edit="scope.isEdit"
        />
      </template>

      <template #floor="scope">
        <SlotFloor :row-data="scope.rowData" :facility-id="scope.rowData.facilityID"/>
      </template>

      <template #room="scope">
        <SlotRoom :row-data="scope.rowData" :floor-id="scope.rowData.floorID"/>
      </template>

      <template #bed="scope">
        <SlotBed :row-data="scope.rowData" :room-id="scope.rowData.roomID"/>
      </template>
      <template #photo="scope">
        <SlotPhoto ref="photo" :row-data="scope.rowData" :is-edit="scope.isEdit"/>
      </template>
      <template #inputs="scope">
        <slot-inputs
          :is-edit="scope.isEdit"
          ref="slot_inputs"
          :root-item="scope.rootItem"
          :items="row_data['rfidTags']"
          @updateValues="(value) => row_data['rfidTags'] = value"
          :show-add-btn="false"
          :show-delete-btn="false"
        />
      </template>
    </edit-field>
  </page-content>
</template>

<script>
import fields, { reloadFieldData } from '@/views/member/fields'
import EditField from '@/components/EditField'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import PageContent from '@/components/PageContent'
import SlotFacility from '@/views/member/SlotFacility'
import SlotFloor from '@/views/member/SlotFloor'
import SlotRoom from '@/views/member/SlotRoom'
import SlotBed from '@/views/member/SlotBed'
import SlotPhoto from '@/views/member/SlotPhoto'
import SlotInputs from '@/components/FieldInputs'

export default {
  name: 'Edit',
  components: {
    SlotPhoto,
    SlotBed,
    SlotRoom,
    SlotFloor,
    SlotFacility,
    SlotInputs,
    PageContent,
    EditFieldHeader,
    EditField,
    fields
  },
  data() {
    return {
      title: common.getMenuName('member') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {}
    }
  },
  created() {
    reloadFieldData()
  },
  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/member/GetMember?memberID=' + this.id)
      .then(res => {
        this.row_data = res.data.member
      })
  },
  methods: {
    beforeSubmit(callback) {
      const rowData = JSON.parse(JSON.stringify(this.$refs.edit_field.rowData));
      ['birthDate', 'joinedDate'].forEach((key) => {
        if (![null, undefined].includes(rowData[key]) && isNaN(Date.parse(rowData[key]))) {
          rowData[key] = null
        }
      })
      callback(true, rowData)
    }
  }
}
</script>

<style scoped>

</style>
